<template>
  <div class="content">
    <div class="content-left">
      <a-card title="客户详情" style="width: 100%">
        <div><span>电话号码:</span>{{ number.number }}</div>
        <div><span>客户类型:</span>{{ handleType(number.result_type) }}</div>
        <div><span>话术模板:</span> {{ number.talk_temp_name }}</div>
        <div><span>通话时长:</span> {{ number.bill }} 秒</div>
        <!-- <div><span>性 别:</span> {{ number.gender === "1" ? "男" : "女" }}</div> -->
        <audio
          style="width: 100%; height: 34px"
          controls="controls"
          :src="number.all_wav"
        ></audio>
      </a-card>
      <a-card title="通话记录" style="width: 100%">
        <div><span>呼叫状态:</span> 呼叫完成</div>
        <div><span>拨号时间:</span> {{ number.calldate }}</div>
        <div><span>接听时间:</span> {{ number.answerdate }}</div>
      </a-card>
    </div>
    <div class="content-right">
      <audio id="audioplay" style="visibility: hidden"></audio>
      <div v-for="(item, index) in records" :key="index">
        <div class="robot" v-if="item.current && item.info_talk_info">
          <div class="robot-top">
            {{
              item.switch === 1
                ? "主流程:"
                : item.switch === 2
                ? "知识库:"
                : item.switch === 3
                ? "多轮会话:"
                : "全局:"
            }} {{ item.process_name }}
          </div>
          <div class="robot-but">
            <!-- <img src="../../assets/robots-avator.png" /> -->
            <img src="../assets/robots-avator.png" />
            <div class="robot-but-middle">
              <div class="yuyin" @click="handlePlay(index)">
                <img v-if="item.play" src="../assets/robot_sign1.gif" />
                <img v-else src="../assets/robot_sign2.png" />
              </div>
              <div class="wenzi">
                {{ item.info_talk_info }}
              </div>
            </div>
          </div>
        </div>
        <div v-else-if="!item.current && item.info_talk_info">
          <div class="user">
            <div class="biaoshi2">{{ item.input_duration }}</div>
            <div class="user-but-middle">
              <div class="wenzi2">{{ item.info_talk_info }}</div>
              <div class="yuyin2" @click="handlePlay(index)">
                <img v-if="item.play" src="../assets/user_sign1.gif" />
                <img v-else src="../assets/user_sign2.png" />
              </div>
            </div>
            <img class="avator" src="../assets/user-avator.png" />
          </div>
          <div style="color: red; text-align: right; margin-right: 52px">
            {{
              item.grep === "DONE"
                ? `默认匹配${item.info_talk_info.replace("DONE", "")}`
                : item.matching_keyword
                ? "匹配关键字[" + item.matching_keyword + "]"
                : "[未识别]"
            }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CallLog",
  props: ["number", "records"],
  setup(props, { emit }) {
    // console.log(props.records)
    const handlePlay = (index) => {
      emit("playVideo", index);
      let audio = document.querySelector("#audioplay");
      audio.src = props.records[index].record;
      audio.play();
    };
    function handleType(num) {
      switch (num) {
        case 7:
          return "F";
        case 6:
          return "E";
        case 5:
          return "D";
        case 4:
          return "C";
        case 3:
          return "B";
        case 2:
          return "A";
      }
    }
    return { handlePlay, handleType };
  },
};
</script>

<style scoped>
.content {
  height: 500px;
  display: flex;
}
.content .content-left {
  flex: 2;
  /* margin-right: 10px; */
  background-color: #fff;
}
.content .content-right {
  flex: 5;
  background-color: #fff;
}
.ant-modal-content {
  min-height: 764px;
}
.ant-modal-body {
  min-height: 656px !important;
}
.content-left {
  overflow: auto;
}
.content-left::-webkit-scrollbar {
  display: none; /* Chrome Safari */
}
.content-left div span {
  margin-right: 4px;
  display: inline-block;
}
.content-left .ant-card {
  background-color: #f8f8f8;
}
.content-left .ant-card:nth-child(1) {
  margin-bottom: 15px;
}
.content-right {
  padding: 10px 0px 10px 10px;
  overflow: auto;
}
.content-right::-webkit-scrollbar {
  display: none; /* Chrome Safari */
}
.robot .robot-top {
  margin-left: 54px;
  background: #e7f6fe;
  text-align: center;
  color: #2990e5;
  display: inline-block;
  padding: 3px 5px;
  margin-bottom: 3px;
}
.robot .robot-but {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.robot-but-middle {
  height: 100%;
  display: flex;
  position: relative;
}
.yuyin,
.wenzi {
  background-color: #f1f1f1;
  min-height: 43px;
  display: flex;
  align-items: center;
  padding: 8px;
}
.yuyin {
  margin: 0 4px 0 8px;
  cursor: pointer;
  min-width: 36px;
  /* margin-bottom: 10px; */
}
.robot-but-middle::before {
  content: "";
  width: 0;
  height: 0;
  position: absolute;
  top: 40%;
  left: 2px;
  border-top: 6px solid transparent;
  border-right: 6px solid #f1f1f1;
  border-bottom: 6px solid transparent;
}
.wenzi {
  margin-right: 20px;
  max-width: 400px;
  /* margin-bottom: 10px; */
}
.biaoshi {
  color: red;
}
.user {
  display: flex;
  justify-content: flex-end;
  position: relative;
  align-items: center;
}
.user .avator {
  width: 46px;
  height: 46px;
}
.user-but-middle {
  display: flex;
  height: 100%;
  margin-left: 20px;
}
.user-but-middle::before {
  content: "";
  width: 0;
  height: 0;
  position: absolute;
  top: 40%;
  right: 48px;
  border-top: 6px solid transparent;
  border-left: 6px solid #9eea6a;
  border-bottom: 6px solid transparent;
}
.yuyin2,
.wenzi2 {
  background-color: #9eea6a;
  min-height: 43px;
  display: flex;
  align-items: center;
  padding: 8px;
}
.yuyin2 {
  margin: 0 8px 0 4px;
  cursor: pointer;
  min-width: 36px;
}
.wenzi2 {
  max-width: 300px;
}
.biaoshi2 {
  color: #666;
}
</style>